import * as React from 'react';
import { graphql } from 'gatsby';
import Layout from '../layout/Layout';

// Please note that you can use https://github.com/dotansimha/graphql-code-generator
// to generate all types from graphQL schema
interface Props {
  data: {
    site: {
      siteMetadata: {
        title: string;
      };
    };
  };
}

const Index = ({ data }: Props): JSX.Element => (
  <Layout home>
    <h2>Welcome</h2>

    <p>
      Welcome to the internet's top source for Tim Minchin sheet music, chords,
      tabs, and instructional videos. Get started by searching for a song in the
      search box to the left.
    </p>

    <h2>Submissions</h2>

    <p>
      If you would like to have any of your own Tim Minchin chords, sheet music,
      or videos displayed on this site, just email me at the address below.
    </p>

    <p>
      If you wish to make a correction to any of these musics, or if you are the
      original author and wish to alter your credit or have your music removed,
      please send an e-mail to{' '}
      <a href="mailto:mike@berman.xyz">mike@berman.xyz</a>.
    </p>
  </Layout>
);

export default Index;

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
